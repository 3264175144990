<template>
  <a-button type="primary" @click="retrySimCardCarrierTasks">重跑</a-button>
</template>

<script>
import { retrySimCardCarrierTasks } from '@/api/sim_card_carrier_task'

export default {
  name: 'RetrySimCardCarrierTasks',
  props: {
    selectedRowKeys: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      submitting: false
    }
  },
  methods: {
    retrySimCardCarrierTasks(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }

      const selectedRowKeys = this.selectedRowKeys
      if (selectedRowKeys.length === 0) {
        this.$warning({
          title: '请选择操作对象',
          content: ''
        })
      } else {
        this.submitting = true
        retrySimCardCarrierTasks(selectedRowKeys).then((res) => {
          if (res.code === 0) {
            // 告知父组件已完成
            this.$emit('completed')
          }
          this.submitting = false
        })
      }
    }
  }
}
</script>
